import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  title = 'angularowlslider';
  customOptions: any = {
    items: 1,
    dots: false,
    nav: false,
    loop: true,
    slideTransition: 'linear',
    lazyLoad: true,
    autoplay: true,
    autoplayTimeout: 10000,
    stagePadding: 0
  };

  images = [
    '/assets/images/ray-2.jpg',
    '/assets/images/bob.jpg',
    '/assets/images/kenny-1.jpg',
    '/assets/images/david.jpg',
    '/assets/images/ray-1.jpg',
    '/assets/images/bob-2.jpg',
    '/assets/images/kenny-2.jpg',
    '/assets/images/david-2.jpg',

    // '/assets/images/jimmie.jpg',
    // '/assets/images/mark.jpg'
  ];

  constructor() { }

  ngOnInit() {
  }

}
