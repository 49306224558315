import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Song } from 'src/app/classes/song';

@Component({
  selector: 'app-songlist',
  templateUrl: './songlist.component.html',
  styleUrls: ['./songlist.component.scss']
})

export class SonglistComponent implements OnInit {
  coverSongs: any;
  originalSongs: any;
  loadingOriginalSongs = true;
  loadingCoverSongs = true;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getOriginalSongs()
    .subscribe(songs => {
        this.originalSongs = songs as Song[];
        this.dataService.sortArray(this.originalSongs, 'name', true);
        this.loadingOriginalSongs = false;
    });

    this.dataService.getCoverSongs()
    .subscribe(songs => {
        this.coverSongs = songs as Song[];
        this.dataService.sortArray(this.coverSongs, 'name', true);
        this.loadingCoverSongs = false;
    });
  }
}

