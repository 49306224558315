import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { Gig } from 'src/app/classes/gig';
// import { ColumnMode } from '@swimlane/ngx-datatable';

const monthFormat = 'MMM YYYY';
const dateFormat = 'MMM DD YYYY';
const startTimeFormat = 'hh:mm';
const endTimeFormat = 'hh:mma';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: [
    './calendar.component.scss',
    '../../../../node_modules/@swimlane/ngx-datatable/themes/dark.scss',
    '../../../../node_modules/@swimlane/ngx-datatable/themes/material.scss',
    '../../../../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss'
  ]
})
export class CalendarComponent implements OnInit {
  pastGigs: Gig[] = [];
  futureGigs: Gig[] = [];
  loadingCalendar = true;
  // ColumnMode = ColumnMode;
  columns = [
    { prop: 'displayDate', flexGrow: 1 },
    { prop: 'place', flexGrow: 1 },
    { prop: 'desc', flexGrow: 1 },
    { prop: 'time', flexGrow: 1 }
  ];
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getPastGigs().subscribe(data => {
      data.forEach(g => {
        const gig: Gig = new Gig();
        gig.group = moment(g.date).format(monthFormat);
        gig.date = new Date(g.date);
        gig.place = g.location;
        gig.desc = g.desc;
        // gig.desc = g.desc === '' ? '-' : g.desc;
        const start = moment(g.date).format('YYYY-MM-DD HH:mm');
        const end = moment(g.date, 'HH:mm:ss').add(g.duration, 'hour').format('YYYY-MM-DD HH:mm');
        const gigStartTime = moment(start).format('00') === '00' ? moment(start).format('h') : moment(start).format('h:mm');
        const gigEndTime = moment(end).format('00') === '00' ? moment(end).format('ha') : moment(end).format('h:mma');
        gig.time = gigStartTime + '-' + gigEndTime;
        if (gig.desc !== 'Wednesday Night Jam') {
          this.pastGigs.push(gig);
        }
      });
      // this.add_thursday_night_jams_past();
      this.pastGigs.forEach(gig => {
        gig.displayDate = moment(gig.date).format(dateFormat);
        gig.group = moment(gig.date).format(monthFormat);
      });
      this.dataService.sortArray(this.pastGigs, 'date', false);
      this.pastGigs = this.pastGigs.slice(0, 20);
    });

    this.dataService.getFutureGigs().subscribe(data => {
      data.forEach(g => {
        const gig: Gig = new Gig();
        gig.group = moment(g.date).format(monthFormat);
        gig.date = new Date(g.date);
        gig.place = g.location;
        gig.desc = g.desc;
        gig.displayDate = moment(g.date).format(dateFormat);
        gig.group = moment(gig.date).format(monthFormat);

        const minutes = 60 * g.duration;
        const start = new Date(g.date);
        const end = new Date(gig.date.setMinutes(gig.date.getMinutes() + minutes));
        const gigStartTime = moment(start).format('mm') === '00' ? moment(start).format('h') : moment(start).format('h:mm');
        const gigEndTime = moment(end).format('mm') === '00' ? moment(end).format('ha') : moment(end).format('h:mma');
        gig.time = gigStartTime + '-' + gigEndTime;
        if (gig.desc !== 'Wednesday Night Jam') {
          this.futureGigs.push(gig);
        }
      });
      // this.add_thursday_night_jams_future();
      // this.futureGigs.forEach(gig => {
      //   gig.displayDate = moment(gig.date).format(dateFormat);
      //   gig.group = moment(gig.date).format(monthFormat);
      // });
      this.dataService.sortArray(this.futureGigs, 'date', true);
      // I don't know why this line is needed, but I just set it to 1000 to select all
      this.futureGigs = this.futureGigs.slice(0, 1000);
      // console.log('futureGigs', this.futureGigs);
      console.log('futureGigs', this.futureGigs);
      console.log('pastGigs', this.pastGigs);
      this.loadingCalendar = false;
    });
  }

  add_thursday_night_jams_past() {
    const startDate: any = new Date('Jan 12, 2017');
    const endData: any = new Date();
    const date: any = startDate;

    while (date < endData) {
      const gig: Gig = new Gig();
      gig.group = moment(gig.date).format(monthFormat);
      gig.date = new Date(date);
      gig.time = '7:30 - 11pm';
      gig.desc = 'Thursday Night Jam';
      gig.place = 'The Lazy Toad - Topeka, KS';
      this.pastGigs.push(gig);
      date.setDate(date.getDate() + 7);
    }
  }

  add_thursday_night_jams_future() {
    const startDate: any = new Date();
    while (moment(startDate).day() !== 4) {
      startDate.setDate(startDate.getDate() + 1);
    }
    console.log('startDate', startDate);
    // const endData: any = new Date('Dec 31, 2020');
    const endData: any = moment(startDate).add(30, 'd');
    console.log('endData', endData);
    const date: any = startDate;

    while (date < endData) {
      const gig: Gig = new Gig();
      gig.group = moment(gig.date).format(monthFormat);
      gig.date = new Date(date);
      gig.time = '7:30 - 11pm';
      gig.desc = 'Thursday Night Jam';
      gig.place = 'The Lazy Toad - Topeka, KS';
      this.futureGigs.push(gig);
      date.setDate(date.getDate() + 7);
    }
  }
}
