export class Gig {
  group: string;
  date: Date;
  displayDate?: string;
  time: string;
  desc: string;
  place: string;
  location: string;
  duration: number;
}
