import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarouselComponent } from './components/carousel/carousel.component';
import { HeaderComponent } from './components/header/header.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { SonglistComponent } from './components/songlist/songlist.component';
import { SectionComponent } from './components/section/section.component';
import { SubSectionHeaderComponent } from './components/sub-section-header/sub-section-header.component';
import { HttpClientModule } from '@angular/common/http';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AngularMaterialModule } from './modules/material/angular-material.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProgressComponent } from './components/progress/progress.component';
import { TestComponent } from './components/test/test.component';

@NgModule({
  declarations: [
    AppComponent,
    CarouselComponent,
    HeaderComponent,
    ContactComponent,
    AboutComponent,
    SonglistComponent,
    SectionComponent,
    SubSectionHeaderComponent,
    CalendarComponent,
    ProgressComponent,
    TestComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    AngularMaterialModule,
    NgxDatatableModule,
    // RouterModule.forRoot([]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
