import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input() header: string;
  @Input() theme: 'dark'|'mid-dark'|'light';
  // @HostBinding('attr.id') id: string;
  ngOnInit(): void {
    // this.id = this.header;
  }
}
