import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-sub-section-header',
  templateUrl: './sub-section-header.component.html',
  styleUrls: ['./sub-section-header.component.scss']
})
export class SubSectionHeaderComponent {

  @Input() header: string;
  @HostBinding('attr.id') id: string = this.header;

}
