import { Injectable } from '@angular/core';
import { Song } from '../classes/song';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Gig } from '../classes/gig';

const monthFormat = 'MMM YYYY';
const dateFormat = 'MMMM D';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  //baseUrl = 'http://localhost:5000/api';
  baseUrl = 'http://www.dacoots.com/api/api';


  constructor(private httpClient: HttpClient) {
  }

  getSongs() {
    const headers = new HttpHeaders();
    // headers.set('Accept', 'application/json; charset=utf-8');
    // headers.set('content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Song[]>(this.baseUrl + '/songs', { headers });
  }

  getCoverSongs() {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json; charset=utf-8');
    headers.set('content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Song[]>(this.baseUrl + '/songs/cover', { headers });
  }

  getOriginalSongs() {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json; charset=utf-8');
    headers.set('content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Song[]>(this.baseUrl + '/songs/original', { headers });
  }

  getFutureGigs() {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json; charset=utf-8');
    headers.set('content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Gig[]>(this.baseUrl + '/gigs/future', { headers });
  }

  getPastGigs() {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json; charset=utf-8');
    headers.set('content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<Gig[]>(this.baseUrl + '/gigs/past', { headers });
  }

  sortArray(array: any, prop: string, asc: boolean): any[] {
    const sorted = array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    if (!asc) { sorted.reverse(); }
    return sorted;
  }
}
